import React from "react";

import { toggleClassName } from "../../../../utils/disabled";
import { observer } from "mobx-react-lite";

import Ustav from "../../../../assets/pdf/documentPage/Ustav_2017.pdf";

import Pologen22 from "../../../../assets/pdf/documentPage/Pologen_2022.pdf";
import Pologen22Dop from "../../../../assets/pdf/documentPage/Pologen_dop.pdf";

import KolDogov from "../../../../assets/pdf/documentPage/Коллективный договор 2024.pdf";
import SamoobsOrchet19 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2019.pdf";
import SamoobsOrchet20 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2020.pdf";
import SamoobsOrchet21 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2021.pdf";
import SamoobsOrchet22 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2022.pdf";
import SamoobsOrchet23 from "../../../../assets/pdf/documentPage/othet_o_samoobsledovanii_2023.pdf";
import Predpis from "../../../../assets/pdf/documentPage/predpisanie_transp_prok1.pdf";
import PismoTransp from "../../../../assets/pdf/documentPage/pismo_transp_prok_2020.pdf";
import { DropDown } from "../../../common";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import { HrLine } from "../../../common";
import rasp1 from "../../../../assets/pdf/documentPage/распоряжения/распоряжение от 18.07.2024.pdf";
import rasp2 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 03.06.2024.pdf";
import rasp3 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 18.08.2023.pdf";
import rasp4 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 11.05.2023.pdf";
import rasp5 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 17.01.2023.pdf";
import rasp6 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 11.04.2022.pdf";
import rasp7 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 11.08.2021.pdf";
import rasp8 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 22.11.2019.pdf";
import rasp9 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 04.03.2019г.pdf";
import rasp10 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 03.04.2018.pdf";
import rasp11 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 16.08.2017.pdf";
import rasp12 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 19.01.2015.pdf";
import rasp13 from "../../../../assets/pdf/documentPage/распоряжения/Распоряжение от 22.09.2014.pdf";
import pravRaspOb from "../../../../assets/pdf/documentPage/новые/Правила внутреннего распорядка.pdf";
import polOPorOform from "../../../../assets/pdf/documentPage/новые/Положение о порядке оформления отношений между ФГБОУ.pdf";
import polOPorPereh from "../../../../assets/pdf/documentPage/новые/Положение о порядке перехода.pdf";
import PolObOtchis from "../../../../assets/pdf/documentPage/новые/Положение об отчислении обучающихся.pdf";
import polOPerev from "../../../../assets/pdf/documentPage/новые/Положение о переводе и восстановлении обучающихся.pdf";
import tekKontr1 from "../../../../assets/pdf/documentPage/новые/Положение о текущем контроле и промежуточной аттестации с ДОТ.pdf";
import tekKontr from "../../../../assets/pdf/documentPage/новые/Положение о проведении текущего контроля и промежуточной аттестации.pdf";
import rezZan from "../../../../assets/pdf/documentPage/новые/Режим занятий обучающихся очного и заочного отделений.pdf";
import pravTrudRasp from "../../../../assets/pdf/documentPage/новые/Правила внутреннего трудового распорядка  ФГБОУ во ВГУВТ.pdf";
import pravila_priema_vo from "../../../../assets/doc/documentPage/Правила приема на обучение по образовательным программам ВО в 2024-25 учебном году.docx";
import pravila_priema from "../../../../assets/pdf/documentPage/local/1. Правила приема на обучение по образовательным программам среднего профессионального образования на 2023-24 учебный год.pdf";
import polAtes from "../../../../assets/pdf/documentPage/Положение об итоговой аттестации по образоват. программам СПО.pdf";
import poleos from "../../../../assets/pdf/documentPage/6.2.3.14.04.01.2024 ПЛЖ об ЭИОС.pdf";

export const Document = observer(() => {
  return (
    <div
      itemProp="copy"
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Документы
      </h1>
      <HrLine className="document__hrLine" />
      <div className="doc_container">
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Устав образовательной организации"}
        >
          <ul>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Ustav} target={"_blank"} rel="noreferrer">
                Устав
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp1} target={"_blank"} rel="noreferrer">
                Распоряжение от 18.07.2024г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp2} target={"_blank"} rel="noreferrer">
                Распоряжение от 03.06.2024г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp3} target={"_blank"} rel="noreferrer">
                Распоряжение от 18.08.2023г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp4} target={"_blank"} rel="noreferrer">
                Распоряжение от 11.05.2023г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp5} target={"_blank"} rel="noreferrer">
                Распоряжение от 17.01.2023г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp6} target={"_blank"} rel="noreferrer">
                Распоряжение от 11.04.2022г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp7} target={"_blank"} rel="noreferrer">
                Распоряжение от 11.08.2021г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp8} target={"_blank"} rel="noreferrer">
                Распоряжение от 04.03.2019г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp9} target={"_blank"} rel="noreferrer">
                Распоряжение от 04.03.2019г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp10} target={"_blank"} rel="noreferrer">
                Распоряжение от 03.04.2018г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp11} target={"_blank"} rel="noreferrer">
                Распоряжение от 16.08.2017г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp12} target={"_blank"} rel="noreferrer">
                Распоряжение от 19.01.2015г
              </a>
            </li>
            <li itemProp="ustavDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={rasp13} target={"_blank"} rel="noreferrer">
                Распоряжение от 22.09.2014г
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Pologen22} target={"_blank"} rel="noreferrer">
                Положение о филиале{" "}
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={Pologen22Dop} target={"_blank"} rel="noreferrer">
                Изменения в Положение о филиале (приказ №42 от 01.04.2022)
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Правила внутреннего распорядка обучающихся"}
        >
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="vozDocLink"
                href={pravRaspOb}
                target={"_blank"}
                rel="noreferrer"
              >
                Правила внутреннего распорядка и организации службы курсантов в
                Уфимском филиале ФГБОУ ВО «ВГУВТ»
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Правила внутреннего трудового распорядка"}
        >
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="vozDocLink"
                href={pravTrudRasp}
                target={"_blank"}
                rel="noreferrer"
              >
                Правила внутреннего трудового распорядка ФГБОУ ВО «ВГУВТ»
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Коллективный договор"}
        >
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="vozDocLink"
                href={KolDogov}
                target={"_blank"}
                rel="noreferrer"
              >
                Коллективный договор
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={"Отчеты о результатах самообследовании"}
        >
          <ul>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet23} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2023 год
              </a>
            </li>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet22} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2022 год
              </a>
            </li>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet21} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2021 год
              </a>
            </li>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet20} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2020 год
              </a>
            </li>
            <li itemProp="reportEduDocLink" className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={SamoobsOrchet19} target={"_blank"} rel="noreferrer">
                Отчет о результатах самообследования Уфимского филиала ФГБОУ ВО
                "ВГУВТ" за 2019 год
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={
            "Предписания органов, осуществляющих государственный контроль (надзор) в сфере образования, и копии отчетов об исполнении таких предписаний"
          }
        >
          <h3
            className={`common__container-title ${toggleClassName(
              "title",
              "title-white",
              "title-black",
              "title-contrast"
            )}`}
          >
            Предписания органов, осуществляющих государственный контроль в сфере
            образования и отчеты об их исполнении:
          </h3>
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={Predpis}
                target={"_blank"}
                rel="noreferrer"
              >
                Предписание Уфимской транспортной прокуратуры № 23/1-5-2020 от
                09.09.2020
              </a>
            </li>
          </ul>
          <HrLine className="document__hrLine" />
          <h3
            className={`common__container-title ${toggleClassName(
              "title",
              "title-white",
              "title-black",
              "title-contrast"
            )}`}
          >
            Отчет об исполнении Предписаний:
          </h3>
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a href={PismoTransp} target={"_blank"} rel="noreferrer">
                Письмо на предписание Уфимской транспортной прокуратуры №
                23/1-5-2020 от 09.09.2020 ЭЦП
              </a>
            </li>
          </ul>
        </DropDown>
        <DropDown
          className={`${toggleClassName(
            "btn-show",
            "btn-show-white",
            "btn-show-black",
            "btn-show-contrast"
          )}`}
          classNameActive={`${toggleClassName(
            "btn-show-active",
            "btn-show-white-active",
            "btn-show-black-active",
            "btn-show-contrast-active"
          )}`}
          title={
            "Локальные нормативные акты образовательной организации по основным вопросам организации и осуществления образовательной деятельности"
          }
        >
          <ul>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={pravila_priema_vo}
                target={"_blank"}
                rel="noreferrer"
              >
                Правила приема по образовательным программам высшего образования
                – программам бакалавриата, программам специалитета, программам
                магистратуры в 2024/25 учебном году
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={pravila_priema}
                target={"_blank"}
                rel="noreferrer"
              >
                Правила приема на обучение по образовательным программам
                среднего профессионального образования в Федеральное
                государственное бюджетное образовательное учреждение высшего
                образования «Волжский государственный университет водного
                транспорта» на 2024/25 учебный год
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={rezZan}
                target={"_blank"}
                rel="noreferrer"
              >
                Режим занятий обучающихся очного и заочного отделений Уфимского
                филиала ФГБОУ ВО «ВГУВТ» на 2024-2025 учебный год с 01.09.2024
                по 30.06.2025
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={tekKontr}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение о проведении текущего контроля и промежуточной
                аттестации обучающихся по образовательным программам среднего
                профессионального и высшего образования
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={tekKontr1}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение о текущем контроле и промежуточной аттестации
                обучающихся по образовательным программам среднего
                профессионального и высшего образования с применением
                дистанционных образовательных технологий
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={polOPerev}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение о переводе и восстановлении обучающихся
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={PolObOtchis}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение об отчислении обучающихся по образовательным
                программам среднего профессионального и высшего образования
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={polOPorPereh}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение о порядке перехода обучающихся с платного обучения на
                бесплатное
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={polOPorOform}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение о порядке оформления возникновения, приостановления и
                прекращения отношений между ФГБОУ ВО «ВГУВТ» и обучающимися и
                (или) родителями (законными представителями) несовершеннолетних
                обучающихся
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={polAtes}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение об итоговой аттестации по образовательным программам
                СПО
              </a>
            </li>
            <li className="doc__item">
              <PDF height={"40px"} width={"40px"} />
              <a
                itemProp="prescriptionDocLink"
                href={poleos}
                target={"_blank"}
                rel="noreferrer"
              >
                Положение об электронной информационной образовательной среде
              </a>
            </li>
          </ul>
        </DropDown>
      </div>
    </div>
  );
});
