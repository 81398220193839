import React, { useEffect, useState } from "react";
import { ReactComponent as PDF } from "../../../../../assets/svg/office/pdf.svg";
import { useParams } from "react-router";
export const WorkPrograms = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_URL}/stranicza-rabochej-programmies?filters[id]=${id}&populate[rabochaya_programma][populate]=file`
        );
        const data = await response.json();
        setData(data?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [id]);

  return (
    <>
      {" "}
      <section className="workProgramm__wrapper">
        <h1>{data[0]?.title}</h1>
        <h2>Рабочие программы</h2>
        <table>
          <thead>
            <tr>
              <th>№ п/п</th>
              <th>Наименование дисциплины</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data[0]?.rabochaya_programma?.map((d, i) => (
              <tr key={d.id}>
                <td>{i + 1}</td>
                <td>
                  {" "}
                  <PDF height={"25px"} width={"25px"} />
                  <a
                    itemProp="educationRpd"
                    href={`${process.env.REACT_APP_STRAPI_UPLOAD}/${d.file.url}`}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {d.title}
                  </a>
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  );
};
