import React from "react";

import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";

export const InfoOResultPriema = ({ data }) => {
  const title = data?.info_o_resultatah_priema?.title;
  const file = data?.info_o_resultatah_priema?.file;
  return (
    <>
      {" "}
      <ul>
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="eduPriemEl"
            href={process.env.REACT_APP_STRAPI_UPLOAD + file?.url}
            target={"_blank"}
            rel="noreferrer"
          >
            {title}
          </a>
        </li>
      </ul>
    </>
  );
};
