import React from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { EducationContainer } from "../../../common";
import { observer } from "mobx-react-lite";
import doc1 from "../../../../assets/pdf/learning/voko/Отчет по ВОКО Уфимский филиал 2023.pdf";
import doc2 from "../../../../assets/pdf/learning/voko/6.2.3.28.04.02.2024 ПЛЖ о ВОКО.pdf";
import doc3 from "../../../../assets/pdf/learning/voko/приказ № 388-с о 24.09.2024 ВОКО.pdf";
import doc4 from "../../../../assets/pdf/learning/voko/Приказ № 480 от 25.09.2024 О проведении внутренней оценки качества образовательной деятельности.pdf";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
export const Voko = observer(() => {
  return (
    <section
      className={toggleClassName(
        "education__container",
        "education__container-white",
        "education__container-black",
        "education__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        Внутренняя оценка качества образовательной деятельности
      </h1>

      <EducationContainer>
        <p>
          <PDF width="25px" height="25px" />{" "}
          <a
            href={doc1}
            target={"_blank"}
            rel="noreferrer"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Отчет по внутренней оценке качества образовательной деятельности (за
            2023 календарный год)
          </a>{" "}
        </p>
        <p>
          <PDF width="25px" height="25px" />{" "}
          <a
            href={doc2}
            target={"_blank"}
            rel="noreferrer"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Положение о внутренней оценке качества образовательной деятельности
          </a>{" "}
        </p>
        <p>
          <PDF width="25px" height="25px" />{" "}
          <a
            href={doc3}
            target={"_blank"}
            rel="noreferrer"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Приказ 24.09.2024 № 388-с г. Нижний Новгород О проведении ВОКО за
            2023-2024 уч.год
          </a>{" "}
        </p>
        <p>
          <PDF width="25px" height="25px" />{" "}
          <a
            href={doc4}
            target={"_blank"}
            rel="noreferrer"
            className={toggleClassName(
              "education__link",
              "education__link-white",
              "education__link-black",
              "education__link-contrast"
            )}
          >
            Приказ 25.09.24 №480 о проведении внутренней оценки качества
            образовательной деятельности
          </a>{" "}
        </p>
      </EducationContainer>
    </section>
  );
});
