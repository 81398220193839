import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";

export const InfoOResultatahPerevoda = ({ data }) => {
  const title = data?.informaciya_o_rezultatah_perevoda?.title;
  const file = data?.informaciya_o_rezultatah_perevoda?.file;

  return (
    <ul>
      <li>
        <PDF height={"25px"} width={"25px"} />
        <a
          itemProp="eduPerevodEl"
          href={process.env.REACT_APP_STRAPI_UPLOAD + file?.url}
          target={"_blank"}
          rel="noreferrer"
        >
          {title}
        </a>
      </li>
    </ul>
  );
};
