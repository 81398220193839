import React from "react";
import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";

export const InfoOChislenObuch = ({ data }) => {
  const chislenTitle = data?.informaciya_o_chislennosti_obuchaushihsya?.title;
  const chislenFile = data?.informaciya_o_chislennosti_obuchaushihsya?.file;
  const chislenInTitle =
    data?.informaciya_o_chislennosty_inostrannih_studentov?.title;
  const chislenInFile =
    data?.informaciya_o_chislennosty_inostrannih_studentov?.file;
  return (
    <>
      <ul>
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="eduChislenEl"
            href={process.env.REACT_APP_STRAPI_UPLOAD + chislenFile?.url}
            target={"_blank"}
            rel="noreferrer"
          >
            {chislenTitle && chislenTitle}
          </a>
        </li>{" "}
        <li>
          <PDF height={"25px"} width={"25px"} />
          <a
            itemProp="eduChislenEl"
            href={process.env.REACT_APP_STRAPI_UPLOAD + chislenInFile?.url}
            target={"_blank"}
            rel="noreferrer"
          >
            {chislenInTitle && chislenInTitle}
          </a>
        </li>
      </ul>
    </>
  );
};
