import React from "react";
import raspisanie from "../../../assets/doc/schedule/vo/2 неделя 2 семестр ВО.docx";
import raspisanie2 from "../../../assets/doc/schedule/vo/3 неделя ВО.odt";
import giaZaochnoe from "../../../assets/pdf/schedule/Расписание зачетно-экзаменационной сессии студентов высшего образования.pdf";
import { Col, Row } from "antd";
export const AutoScheduleVo = () => {
  return (
    <div className="autoScheduleVo-wrapper">
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <div
            style={{ padding: "0px 25px" }}
            className="auto-schedule__container-buttons-selectDay"
          >
            {" "}
            <a
              style={{
                textDecoration: "none",
                textAlign: "center",
                padding: "5px",
                height: "auto",
              }}
              href={raspisanie}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать расписание на 2 неделю
            </a>
            <a
              style={{
                textDecoration: "none",
                textAlign: "center",
                padding: "5px",
                height: "auto",
              }}
              href={raspisanie2}
              target={"_blank"}
              rel="noreferrer"
            >
              Скачать расписание на 3 неделю
            </a>
          </div>
          <a
            style={{
              textDecoration: "none",
              textAlign: "center",
              padding: "5px",
              height: "auto",
            }}
            href={giaZaochnoe}
            target={"_blank"}
            rel="noreferrer"
          >
            Расписание зачетно-экзаменационной сессии студентов высшего
            образования
          </a>
        </Col>
      </Row>
    </div>
  );
};
