/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";

import { ReactComponent as PDF } from "../../../../assets/svg/office/pdf.svg";
import { TableWrapper } from "../../../common";
import { VOEkspluatSudEnergUstanovok_23_260506 } from "./neadaptirovannie-programmi/23_260506_VO_ekspluat-sud-energ-ustanovok";
import { SPOSudovojdenie_22_260203 } from "./neadaptirovannie-programmi/22_260203_SPO_sudovojdenie";
import { SPOSudovojdenie_21_260203 } from "./neadaptirovannie-programmi/21_260203_SPO_sudovojdenie";
import { SPOSudovojdenie_20_260203 } from "./neadaptirovannie-programmi/20_260203_SPO_sudovojdenie";
import { SPOEkspluatSudovEelectrooborud_22_260206 } from "./neadaptirovannie-programmi/22_260206_SPO_expluat_sudov_electroob";
import { SPOEkspluatSudovEelectrooborud_21_260206 } from "./neadaptirovannie-programmi/21_260206_SPO_expluat_sudov_electroob";
import { SPOEkspluatSudovEnergUstanovok_22_260205 } from "./neadaptirovannie-programmi/22_260205_SPO_expluat_sudov_energ_ustanovok";
import { SPOEkspluatSudovEnergUstanovok_21_260205 } from "./neadaptirovannie-programmi/21_260205_SPO_expluat_sudov_energ_ustanovok";
import { SPOOrganiz_perevoz_upravlenia_22_230201 } from "./neadaptirovannie-programmi/22_230201_SPO_organiz_perevoz_upravleniya";
import { SPOOrganiz_perevoz_upravlenia_21_230201 } from "./neadaptirovannie-programmi/21_230201_SPO_organiz_perevoz_upravleniya";
import { SPOSudovojdenie_23_260203 } from "./neadaptirovannie-programmi/23_260203_SPO_sudovojdenie";
import { SPOEkspluatSudovEelectrooborud_23_260206 } from "./neadaptirovannie-programmi/23_260206_SPO_expluat_sudov_electroob";
import { SPOEkspluatSudovEnergUstanovok_23_260205 } from "./neadaptirovannie-programmi/23_260205_SPO_expluat_sudov_energ_ustanovok";
import { SPOOrganiz_perevoz_upravlenia_23_230201 } from "./neadaptirovannie-programmi/23_230201_SPO_organiz_perevoz_upravleniya";
import { SPOSudovojdenie_24_260203 } from "./neadaptirovannie-programmi/24_260203_SPO_sudovojdenie";
import { SPOEkspluatSudovEnergUstanovok_24_260205 } from "./neadaptirovannie-programmi/24_260205_SPO_expluat_sudov_energ_ustanovok";
import { SPOEkspluatSudovEelectrooborud_24_260206 } from "./neadaptirovannie-programmi/24_260206_SPO_expluat_sudov_electroob";
import { NavLink } from "react-router-dom";

export const NeadaptirovannieProgrammi = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_URL}/informacziya-po-obrazovatelnym-programmams?pagination[pageSize]=9999&populate[silka_na_opisanie_obrazovat_programmi][populate]=file&populate[naimenovanie_professii][populate]=stranicza_rabochej_programmy&populate[obrazovatelnaya_programma][populate]=stranicza_rabochej_programmy&populate[silka_na_opisanie_obrazovat_programmi][populate]=file&populate[silka_na_uchebnii_plan][populate]=file&populate[silka_na_rab_programmu][populate]=stranicza_rabochej_programmy&populate[silka_na_kalendarnii_uchebnii_grafic][populate]=file&populate[silka_na_rp_praktic][populate]=file&populate[silka_na_method_doc][populate]=file
`
        );
        const data = await response.json();
        setData(data?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);
  console.log(data);
  return (
    <TableWrapper title="Информация по образовательным программам:">
      <table>
        <tbody>
          <tr itemProp="eduOp">
            <td itemProp="eduCode">Код, шифр</td>
            <td itemProp="eduName">
              Наименование профессии, специальности, направления подготовки,
              наименование группы научных специальностей
            </td>
            <td itemProp="eduLevel">Уровень образования</td>
            <td itemProp="eduProf">
              Образовательная программа, направленность, профиль, шифр и
              наименование научной специальности
            </td>
            <td itemProp="eduForm">Реализуемые формы обучения</td>
            <td itemProp="opMain">
              Ссылка на описание образовательной программы с приложением ее
              копии
            </td>
            <td itemProp="educationPlan">Ссылка на учебный план</td>

            <td itemProp="educationRpd">
              Ссылки на рабочие программы (по каждой дисциплине в составе
              образовательной программы)
            </td>
            <td itemProp="educationShedule">
              Ссылка на календарный учебный график
            </td>
            <td itemProp="eduPr">
              Ссылка на рабочие программы практик, предусмотренных
              соответствующей образовательной программой
            </td>

            <td itemProp="methodology">
              Ссылка на методические и иные документы, разработанные ОО для
              обеспечения образовательного процесса, а также рабочие программы
              воспитания и календарные планы воспитательной работы, включаемых в
              ООП
            </td>
          </tr>
          {data?.map((d) => (
            <tr key={d.id} itemProp="eduOp">
              <td itemProp="eduCode">{d.cod}</td>
              <td>
                <NavLink
                  itemProp="eduName"
                  to={`/educationPrograms/workPrograms/${d.naimenovanie_professii.stranicza_rabochej_programmy.id}`}
                >
                  {d.naimenovanie_professii.title}
                </NavLink>
              </td>
              <td itemProp="eduLevel">{d.uroven_obrazovaniya}</td>
              <td>
                <NavLink
                  itemProp="eduProf"
                  to={`/educationPrograms/workPrograms/${d.obrazovatelnaya_programma.stranicza_rabochej_programmy.id}`}
                >
                  {d.obrazovatelnaya_programma.title}
                </NavLink>
              </td>
              <td itemProp="eduForm">{d.forma_obucheniya}</td>
              <td>
                <ul>
                  {d?.silka_na_opisanie_obrazovat_programmi?.map((f) => (
                    <li key={f.id} className="doc__item">
                      <PDF height={"25px"} width={"25px"} />
                      <a
                        itemProp="opMain"
                        href={`${process.env.REACT_APP_STRAPI_UPLOAD}/${f.file.url}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {f.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </td>
              <td>
                <ul>
                  {d?.silka_na_uchebnii_plan?.map((f) => (
                    <li key={f.id} className="doc__item">
                      <PDF height={"25px"} width={"25px"} />
                      <a
                        itemProp="educationPlan"
                        href={`${process.env.REACT_APP_STRAPI_UPLOAD}/${f.file.url}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {f.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </td>
              <td>
                <ul>
                  <li className="doc__item">
                    <NavLink
                      itemProp="educationRpd"
                      to={`/educationPrograms/workPrograms/${d.silka_na_rab_programmu.stranicza_rabochej_programmy.id}`}
                    >
                      {d.silka_na_rab_programmu.title}
                    </NavLink>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  {d?.silka_na_kalendarnii_uchebnii_grafic?.map((f) => (
                    <li key={f.id} className="doc__item">
                      <PDF height={"25px"} width={"25px"} />
                      <a
                        itemProp="educationShedule"
                        href={`${process.env.REACT_APP_STRAPI_UPLOAD}/${f.file.url}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {f.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </td>
              <td>
                <ul>
                  {d?.silka_na_rp_praktic?.map((f) => (
                    <li key={f.id} className="doc__item">
                      <PDF height={"25px"} width={"25px"} />
                      <a
                        itemProp="educationShedule"
                        href={`${process.env.REACT_APP_STRAPI_UPLOAD}/${f.file.url}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {f.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </td>

              <td>
                <ul>
                  {d?.silka_na_method_doc?.map((f) => (
                    <li key={f.id} className="doc__item">
                      <PDF height={"25px"} width={"25px"} />
                      <a
                        itemProp="educationShedule"
                        href={`${process.env.REACT_APP_STRAPI_UPLOAD}/${f.file.url}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {f.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
          {/*  <VOEkspluatSudEnergUstanovok_23_260506 PDF={PDF} /> */}
          <SPOSudovojdenie_24_260203 PDF={PDF} />
          <SPOSudovojdenie_23_260203 PDF={PDF} />
          <SPOSudovojdenie_22_260203 PDF={PDF} />
          <SPOSudovojdenie_21_260203 PDF={PDF} />
          <SPOSudovojdenie_20_260203 PDF={PDF} />
          <SPOEkspluatSudovEelectrooborud_24_260206 PDF={PDF} />
          <SPOEkspluatSudovEelectrooborud_23_260206 PDF={PDF} />
          <SPOEkspluatSudovEelectrooborud_22_260206 PDF={PDF} />
          <SPOEkspluatSudovEelectrooborud_21_260206 PDF={PDF} />
          <SPOEkspluatSudovEnergUstanovok_24_260205 PDF={PDF} />
          <SPOEkspluatSudovEnergUstanovok_23_260205 PDF={PDF} />
          <SPOEkspluatSudovEnergUstanovok_22_260205 PDF={PDF} />
          <SPOEkspluatSudovEnergUstanovok_21_260205 PDF={PDF} />
          <SPOOrganiz_perevoz_upravlenia_23_230201 PDF={PDF} />
          <SPOOrganiz_perevoz_upravlenia_22_230201 PDF={PDF} />
          <SPOOrganiz_perevoz_upravlenia_21_230201 PDF={PDF} />
        </tbody>
      </table>
      * Информация по реализуемым общеобразовательным программам: программа
      основного общего образования является частью Основных профессиональных
      образовательных программ СПО по специальностям
    </TableWrapper>
  );
};
