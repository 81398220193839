import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toggleClassName } from "../../../../utils/disabled";
import { TableWrapper } from "../../../common";

export const Vacant = observer(() => {
  const [vacants, setVacants] = useState([]);
  const pageSize = 9999;

  useEffect(() => {
    try {
      async function fetchData() {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_URL}/vakantnye-mesta?populate=vacant&pagination[pageSize]=${pageSize}`
        );

        const vacancies = await response.json();

        setVacants(vacancies?.data);
      }
      fetchData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  function countStudents(arr) {
    const initialValue = {
      federalnii_budjet: 0,
      budjet_subektov: 0,
      mestnii_budjet: 0,
      budjet_fiz_i_ur_lic: 0,
    };
    return arr?.reduce((sum, cur) => {
      for (let key in initialValue) {
        sum[key] += Number(cur[key]) || 0;
      }
      return sum;
    }, initialValue);
  }

  return (
    <div
      className={toggleClassName(
        "common__container",
        "common__container-white",
        "common__container-black",
        "common__container-contrast"
      )}
    >
      <h1
        className={`common__container-title ${toggleClassName(
          "title",
          "title-white",
          "title-black",
          "title-contrast"
        )}`}
      >
        {vacants?.title}
      </h1>
      <TableWrapper>
        <table>
          <tbody>
            <tr>
              <td>Код, шифр группы научных специальностей</td>
              <td>
                Наименование профессии, специальности, направления подготовки,
                наименование группы научных специальностей{" "}
              </td>
              <td>Уровень образования</td>
              <td>
                Образовательная программа, направленность, профиль, шифр и
                наименование научной специальности
              </td>
              <td>Курс</td>
              <td>Форма обучения</td>
              <td>
                Количество вакантных мест для приема (перевода) на места,
                финансируемые за счет бюджетных ассигнований федерального
                бюджета
              </td>
              <td>
                Количество вакантных мест для приема (перевода) на места,
                финансируем ые за счет бюджетов субъектов Российской Федерации
              </td>
              <td>
                Количество вакантных мест для приема (перевода) на места,
                финансируем ые за счет местных бюджетов
              </td>
              <td>
                Количество вакантных мест для приема (перевода) за счёт средств
                физических и (или) юридических лиц
              </td>
            </tr>
            {vacants?.vacant?.map((v) => (
              <tr itemProp="vacant" key={v?.id}>
                <td itemProp="eduCode">{v?.code}</td>
                <td itemProp="eduName">{v?.naimenovanie_professii}</td>
                <td itemProp="eduLevel">{v?.uroven_obrazovaniya}</td>
                <td itemProp="eduProf">{v?.obrazovat_programma}</td>
                <td itemProp="eduCourse">{v?.kurse}</td>
                <td itemProp="eduForm">{v?.forma_obusheniya}</td>
                <td itemProp="numberBFVacant">{v?.federalnii_budjet}</td>
                <td itemProp="numberBRVacant">{v?.budjet_subektov}</td>
                <td itemProp="numberBMVacant">{v?.mestnii_budjet}</td>
                <td itemProp="numberPVacant">{v?.budjet_fiz_i_ur_lic}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Итого:</td>
              {vacants?.vacant &&
                Object.values(countStudents(vacants.vacant)).map((v, i) => (
                  <td key={i}>{v}</td>
                ))}
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </div>
  );
});
