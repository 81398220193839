import { observer } from "mobx-react-lite";
import React from "react";
import { toggleClassName, toggleFontSize } from "../../../../utils/disabled";
import { InfoContainer } from "../../../common";
import { Image } from "../../../common";
import prof from "../../../../assets/img/main/prof.png";
import profTask from "../../../../assets/pdf/home/prof/Конкурсное-Задание.docx";
import comp from "../../../../assets/pdf/home/prof/Описание_компетенции.docx";
import matr from "../../../../assets/pdf/home/prof/Матрица_конкурсного_-задания.xlsx";
import list from "../../../../assets/pdf/home/prof/Инфраструктурный-лист.xlsx";

import { ReactComponent as DOC } from "../../../../assets/svg/office/word.svg";
import { ReactComponent as EXCEL } from "../../../../assets/svg/office/excel.svg";

export const ProfessionalsPage = observer(() => {
  return (
    <div
      style={{ fontSize: toggleFontSize(18) }}
      className={`infopage-main ${toggleClassName(
        "infopage-main ",
        "infopage-white",
        "infopage-black",
        "infopage-contrast"
      )}`}
    >
      {
        <InfoContainer
          title="Профессионалы"
          newsTitle={
            "Всероссийское чемпионатное движение по профессиональному мастерству"
          }
          image={
            <Image src={prof} alt="Фото уплыло:(" height="100%" width="100%" />
          }
        >
          <div>
            <p>
              Всероссийское чемпионатное движение по профессиональному
              мастерству представляет собой соревновательные мероприятия,
              направленные на демонстрацию компетенций конкурсантами и работу по
              формированию прототипов «продуктов» в определенной экономической
              области.
            </p>
            <p>Миссия движения:</p>
            <p>
              Создание условий и системы мотивации, способствующих повышению
              значимости и престижа рабочих профессий, профессиональному росту
              молодежи путем гармонизации лучших практик и профессиональных
              навыков посредством организации и проведения Чемпионатов
              профессионального мастерства, а также содействие оперативному и
              эффективному кадровому обеспечению различных отраслей экономики.
            </p>
            <p>Основные задачи:</p>
            <ul>
              <li>
                Выявление, развитие и поддержка талантливой, перспективной
                молодёжи и молодых специалистов, обладающих высоким уровнем
                профессионального мастерства и содействие их трудоустройству;
              </li>
              <li>Развитие системы среднего профессионального образования;</li>
              <li>
                Создание новых и развитие существующих профессиональных
                компетенций с учетом специфики субъектов Российской Федерации
                согласно запросам реального сектора экономики;
              </li>
              <li>
                Популяризация наиболее востребованных рабочих профессий,
                компетенций среди подростков и молодежи.
              </li>
            </ul>
            <p>НОРМАТИВНАЯ ДОКУМЕНТАЦИЯ</p>
            <ol>
              <li>
                <DOC height={"25px"} width={"25px"} />
                <a
                  itemProp="eduPerevodEl"
                  href={profTask}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  КОНКУРСНОЕ ЗАДАНИЕ КОМПЕТЕНЦИИ «ЭКСПЛУАТАЦИЯ СУДОВ ВОДНОГО
                  ТРАНСПОРТА»
                </a>
              </li>
              <li>
                <DOC height={"25px"} width={"25px"} />
                <a
                  itemProp="eduPerevodEl"
                  href={comp}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  ОПИСАНИЕ КОМПЕТЕНЦИИ «ЭКСПЛУАТАЦИЯ СУДОВ ВОДНОГО ТРАНСПОРТА»
                </a>
              </li>
              <li>
                <EXCEL height={"25px"} width={"25px"} />
                <a
                  itemProp="eduPerevodEl"
                  href={matr}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Матрица конкурсного задания
                </a>
              </li>
              <li>
                <EXCEL height={"25px"} width={"25px"} />
                <a
                  itemProp="eduPerevodEl"
                  href={list}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Инфраструктурный лист для оснащения конкурсной площадки
                </a>
              </li>
            </ol>
          </div>
        </InfoContainer>
      }
    </div>
  );
});
