import {
  Acts,
  AdminPanel,
  AntiCorruption,
  AntiCorruptionExpertise,
  Catering,
  Commission,
  DistanceEducation,
  DocumentForms,
  Feedback,
  IncomeInformation,
  MakeAnAppointment,
  Managers,
  MethodologicalMaterials,
  Monitoring,
  Nok,
  SiteMap,
  WorkPrograms,
} from "./components/page";
import { Contacts } from "./layouts/contacts";
import { Contacts as ContactsPage } from "./components/page";
import { Reviews } from "./components/page";
import { Vacancies } from "./components/page";
/* import { EditNewsPage } from "./components/page/create-news-page/edit-news-page"; */
import { OrganizationOfTransportation } from "./components/page";
import { OrganizationOfTransportation2020 } from "./components/page";
import { OrganizationOfTransportation2020FOS } from "./components/page";
import { OrganizationOfTransportation2021 } from "./components/page";
import { OrganizationOfTransportation2021FOS } from "./components/page";
import { OrganizationOfTransportation2022 } from "./components/page";
import { OrganizationOfTransportation2023 } from "./components/page";
import { OrganizationOfTransportation2022FOS } from "./components/page";
import { NavigationDepth } from "./components/page";
import { Navigation2019 } from "./components/page";
import { Navigation2019FOS } from "./components/page";
import { Navigation2019FOSZ } from "./components/page";
import { Navigation2020 } from "./components/page";
import { Navigation2020FOS } from "./components/page";
import { Navigation2021 } from "./components/page";
import { Navigation2021FOS } from "./components/page";
import { Navigation2022 } from "./components/page";
import { Navigation2023 } from "./components/page";
import { Navigation2022FOS } from "./components/page";
import { ShipPowerPlants2020 } from "./components/page";
import { ShipPowerPlants2020FOS } from "./components/page";
import { ShipPowerPlants2021 } from "./components/page";
import { ShipPowerPlants2021FOS } from "./components/page";
import { ShipPowerPlants2022 } from "./components/page";
import { ShipPowerPlants2023 } from "./components/page";
import { ShipPowerPlants2022FOS } from "./components/page";
import { MarineElectricalEquipment2020 } from "./components/page";
import { MarineElectricalEquipment2020FOS } from "./components/page";
import { MarineElectricalEquipment2021 } from "./components/page";
import { MarineElectricalEquipment2021FOS } from "./components/page";
import { MarineElectricalEquipment2022 } from "./components/page";
import { MarineElectricalEquipment2022FOS } from "./components/page";
import { MarineElectricalEquipment2023 } from "./components/page";
import { ShipPowerPlantsVO2023 } from "./components/page";
import { ShipPowerPlantsVO2023FOS } from "./components/page";
import { ApplicantsInfo } from "./components/page";
import { Dormitory } from "./components/page";
import { Message } from "./components/page";

import { AdmissionConditions } from "./components/page";
import { EducationCosts } from "./components/page";
import { EducationLvlRequirements } from "./components/page";
import { KcpVo } from "./components/page";
import { KCP } from "./components/page";
import { PassingScores } from "./components/page";
import { Reception } from "./components/page";
import { ReceptionPlaces } from "./components/page";
import { ReceptionTiming } from "./components/page";
import { Spo } from "./components/page";
import { HigherEducation } from "./components/page";
import { OpenDay } from "./components/page";
import { AboutBranch } from "./components/page";
import { HistoricalReference } from "./components/page";
import { Services } from "./components/page";
import { StateForPeople } from "./components/page";
import { UniversityEvents } from "./components/page";
import { UniversityNews } from "./components/page";
import { Cadets } from "./components/page";
import { EducationsPlatforms } from "./components/page";
import { About } from "./components/page";
import { EmploymentService } from "./components/page";
import { Faq } from "./components/page";
import { Partners } from "./components/page";
import { Health } from "./components/page";
import { Parents } from "./components/page";
import { Practice } from "./components/page";
import { Abkadirov } from "./components/page";
import { Interview } from "./components/page";
import { Portnyagina } from "./components/page";
import { Timofeev } from "./components/page";
import { Tokmakov } from "./components/page";
import { OurPride } from "./components/page/live/our-pride/our-pride";
import { Science } from "./components/page/live/science/science";
import { Sport } from "./components/page/live/sport/sport";
import { StudentsLive } from "./components/page/live/students-live/students-live";
import { AutoSchedule } from "./components/page/schedule/auto-schedule";
import { AutoScheduleVo } from "./components/page/schedule/auto-shedule-vo";
import { Budget } from "./components/page";
import { Common } from "./components/page";
import { Document } from "./components/page";
import { LocalActs } from "./components/page/sveden/document/local-acts";
import { EduStandarts } from "./components/page";
import { Education } from "./components/page";
import { Employees } from "./components/page";
import { Grants } from "./components/page";
import { Inter } from "./components/page";
import { Objects } from "./components/page";
import { PaidEdu } from "./components/page";
import { Struct } from "./components/page";
import { Vacant } from "./components/page";
import { AboutCdo } from "./components/page";
import { AddEducationProgramsChildrenAdults } from "./components/page";
import { AdvancedTraining } from "./components/page";
import { DistanceLearning } from "./components/page";
import { ObsherazvivaushieProgrammi } from "./components/page";
import { ProfessionalTrainingPrograms } from "./components/page";
import { ProgrammiProfObuchenia } from "./components/page";
import { ProgrammiProfPerepodgotovki } from "./components/page";
import { RequireAuth } from "./hoc/require-auth";
import { Main } from "./layouts/main";
import { Schedule } from "./layouts/schedule";
import { Training } from "./layouts/training";
import { Sveden } from "./layouts/sveden/sveden";

import { ContactsCdo } from "./components/page/";
import { EducationPrograms } from "./layouts/education-programs";
import { Live } from "./layouts/live";
import { Enrolly } from "./layouts/enrollee";
import { OneNewsPage } from "./components/page/one-news-page/one-news-page";
import { Auth } from "./layouts/auth/auth";
import { Home } from "./layouts/home";
import { SubmissionDoc } from "./components/page";
import { Learning } from "./layouts/learning";
import { VacanciesFrom } from "./components/page";

import { Target } from "./components/page/enrollee/target/target";
import { EmployeePage } from "./components/page/create-meeting/employee-page";
import { Appointment } from "./components/page/make-an-appointment/appointment";
import { Navigation2024 } from "./components/page/educations-programs/260203/navigation2024";
import { ShipPowerPlants2024 } from "./components/page/educations-programs/260205/ship-power-plants2024";
import { MarineElectricalEquipment2024 } from "./components/page/educations-programs/260206/marine-electrical-equipment2024";
import { Certificate } from "./components/page";
import { Voko } from "./components/page/learning/voko/voko";

const routes = () => [
  {
    path: "",
    element: <Main />,
  },
  {
    path: ":id",
    element: <OneNewsPage />,
  },
  /*Авторизированные пользователи*/
  {
    path: "auth",
    element: (
      <RequireAuth>
        <Auth />
      </RequireAuth>
    ),
  },
  {
    path: "admin",
    element: (
      <RequireAuth>
        <AdminPanel />
      </RequireAuth>
    ),
  },
  {
    path: "admin/employee/:id",
    element: (
      <RequireAuth>
        <EmployeePage state="2" />
      </RequireAuth>
    ),
  },

  {
    path: "educationPrograms",
    element: <EducationPrograms />,
    children: [
      {
        path: "workPrograms/:id",
        element: <WorkPrograms />,
      },
      {
        path: "organizationOfTransportation",
        element: <OrganizationOfTransportation />,
      },
      {
        path: "organizationOfTransportation2020",
        element: <OrganizationOfTransportation2020 />,
      },
      {
        path: "organizationOfTransportation2020FOS",
        element: <OrganizationOfTransportation2020FOS />,
      },
      {
        path: "organizationOfTransportation2021",
        element: <OrganizationOfTransportation2021 />,
      },
      {
        path: "organizationOfTransportation2021FOS",
        element: <OrganizationOfTransportation2021FOS />,
      },
      {
        path: "organizationOfTransportation2022",
        element: <OrganizationOfTransportation2022 />,
      },
      {
        path: "organizationOfTransportation2023",
        element: <OrganizationOfTransportation2023 />,
      },
      {
        path: "organizationOfTransportation2022FOS",
        element: <OrganizationOfTransportation2022FOS />,
      },
      {
        path: "navigation2019",
        element: <Navigation2019 />,
      },

      { path: "navigation2019FOS", element: <Navigation2019FOS /> },
      { path: "navigation2019FOSZ", element: <Navigation2019FOSZ /> },
      {
        path: "navigation2020",
        element: <Navigation2020 />,
      },
      { path: "navigation2020FOS", element: <Navigation2020FOS /> },
      {
        path: "navigation2021",
        element: <Navigation2021 />,
      },
      { path: "navigation2022FOS", element: <Navigation2021FOS /> },
      {
        path: "navigation2022",
        element: <Navigation2022 />,
      },
      {
        path: "navigation2023",
        element: <Navigation2023 />,
      },
      {
        path: "navigation2024",
        element: <Navigation2024 />,
      },
      { path: "navigation2022FOS", element: <Navigation2022FOS /> },
      {
        path: "navigationDepth",
        element: <NavigationDepth />,
      },
      {
        path: "shipPowerPlants2020",
        element: <ShipPowerPlants2020 />,
      },
      {
        path: "shipPowerPlants2020FOS",
        element: <ShipPowerPlants2020FOS />,
      },
      {
        path: "shipPowerPlants2021",
        element: <ShipPowerPlants2021 />,
      },
      {
        path: "shipPowerPlants2021FOS",
        element: <ShipPowerPlants2021FOS />,
      },
      {
        path: "shipPowerPlants2022",
        element: <ShipPowerPlants2022 />,
      },
      {
        path: "shipPowerPlants2022FOS",
        element: <ShipPowerPlants2022FOS />,
      },
      {
        path: "shipPowerPlants2023",
        element: <ShipPowerPlants2023 />,
      },
      {
        path: "shipPowerPlants2024",
        element: <ShipPowerPlants2024 />,
      },
      {
        path: "shipPowerPlantsVO2023",
        element: <ShipPowerPlantsVO2023 />,
      },
      {
        path: "shipPowerPlantsVO2023FOS",
        element: <ShipPowerPlantsVO2023FOS />,
      },
      {
        path: "marineElectricalEquipment2020",
        element: <MarineElectricalEquipment2020 />,
      },
      {
        path: "marineElectricalEquipment2020FOS",
        element: <MarineElectricalEquipment2020FOS />,
      },
      {
        path: "marineElectricalEquipment2021",
        element: <MarineElectricalEquipment2021 />,
      },
      {
        path: "marineElectricalEquipment2021FOS",
        element: <MarineElectricalEquipment2021FOS />,
      },
      {
        path: "marineElectricalEquipment2022",
        element: <MarineElectricalEquipment2022 />,
      },

      {
        path: "marineElectricalEquipment2022FOS",
        element: <MarineElectricalEquipment2022FOS />,
      },
      {
        path: "marineElectricalEquipment2023",
        element: <MarineElectricalEquipment2023 />,
      },
      {
        path: "marineElectricalEquipment2024",
        element: <MarineElectricalEquipment2024 />,
      },
    ],
  },
  {
    path: "local-acts",
    element: <LocalActs />,
  },
  {
    path: "schedule",
    element: <Schedule />,
    children: [
      {
        path: "",
        element: <AutoSchedule />,
      },
      {
        path: "vo",
        element: <AutoScheduleVo />,
      },
    ],
  },

  {
    path: "sveden",
    element: <Sveden />,
    children: [
      {
        path: "common",
        element: <Common />,
      },

      {
        path: "catering",
        element: <Catering />,
      },
      {
        path: "managers",
        element: <Managers />,
      },
      {
        path: "budget",
        element: <Budget />,
      },
      {
        path: "document",
        element: <Document />,
      },
      {
        path: "education",
        element: <Education />,
      },
      {
        path: "eduStandarts",
        element: <EduStandarts />,
      },
      {
        path: "employees",
        element: <Employees />,
      },
      {
        path: "grants",
        element: <Grants />,
      },
      {
        path: "inter",
        element: <Inter />,
      },
      {
        path: "objects",
        element: <Objects />,
      },

      {
        path: "paid__edu",
        element: <PaidEdu />,
      },
      {
        path: "struct",
        element: <Struct />,
      },
      {
        path: "vacant",
        element: <Vacant />,
      },
    ],
  },
  {
    path: "live",
    element: <Live />,
    children: [
      {
        path: "interview",
        element: <Interview />,
      },
      {
        path: "timofeev",
        element: <Timofeev />,
      },
      {
        path: "tokmakov",
        element: <Tokmakov />,
      },
      {
        path: "abkadirov",
        element: <Abkadirov />,
      },
      {
        path: "portnyagina",
        element: <Portnyagina />,
      },
      {
        path: "studentsLive",
        element: <StudentsLive />,
      },
      {
        path: "interview",
        element: <Interview />,
      },
      {
        path: "timofeev",
        element: <Timofeev />,
      },
      {
        path: "tokmakov",
        element: <Tokmakov />,
      },
      {
        path: "abkadirov",
        element: <Abkadirov />,
      },
      {
        path: "portnyagina",
        element: <Portnyagina />,
      },
      {
        path: "studentsLive/:id",
        element: <OneNewsPage />,
      },
      {
        path: "sport",
        element: <Sport />,
      },
      {
        path: "sport/:id",
        element: <OneNewsPage />,
      },
      {
        path: "ourPride",
        element: <OurPride />,
      },
      {
        path: "ourPride/:id",
        element: <OneNewsPage />,
      },
      {
        path: "science",
        element: <Science />,
      },
      {
        path: "science/:id",
        element: <OneNewsPage />,
      },
      {
        path: "vacancies",
        element: <Vacancies />,
      },
    ],
  },
  {
    path: "enrollee",
    element: <Enrolly />,
    children: [
      {
        path: "kcp",
        element: <KCP />,
      },
      {
        path: "kcpVo",
        element: <KcpVo />,
      },
      /* Скорее всего этот компонент больше будет не нужен, можно будет удалить */
      /*     {
        path: "applicants",
        element: <ApplicantsEnrollee />,
      }, */
      {
        path: "dormitory",
        element: <Dormitory />,
      },
      {
        path: "messageFromDirector",
        element: <Message />,
      },
      {
        path: "applicantsInfo",
        element: <ApplicantsInfo />,
      },

      {
        path: "monitoring",
        element: <Monitoring />,
      },

      {
        path: "reception",
        element: <Reception />,
      },
      {
        path: "spo",
        element: <Spo />,
      },
      {
        path: "passingScores",
        element: <PassingScores />,
      },
      {
        path: "higherEducation",
        element: <HigherEducation />,
      },
      {
        path: "submissionDoc",
        element: <SubmissionDoc />,
      },
      {
        path: "educationCosts",
        element: <EducationCosts />,
      },
      {
        path: "receptionPlaces",
        element: <ReceptionPlaces />,
      },
      {
        path: "receptionTiming",
        element: <ReceptionTiming />,
      },
      {
        path: "open-day",
        element: <OpenDay />,
      },
      {
        path: "target",
        element: <Target />,
      },
      {
        path: "educationLvlRequirements",
        element: <EducationLvlRequirements />,
      },
      {
        path: "admissionConditions",
        element: <AdmissionConditions />,
      },
    ],
  },
  {
    path: "learning",
    element: <Learning />,
    children: [
      {
        path: "health",
        element: <Health />,
      },
      {
        path: "distanceEducation",
        element: <DistanceEducation />,
      },
      {
        path: "voko",
        element: <Voko />,
      },
      {
        path: "practice",
        element: <Practice />,
      },
      {
        path: "educationsPlatforms",
        element: <EducationsPlatforms />,
      },
      {
        path: "cadets",
        element: <Cadets />,
      },
      {
        path: "parents",
        element: <Parents />,
      },

      {
        path: "employmentService",
        element: <EmploymentService />,
        children: [
          {
            path: "",
            element: <About />,
          },
          {
            path: "faq",
            element: <Faq />,
          },
          {
            path: "partners",
            element: <Partners />,
          },
          {
            path: "vacanciesFrom",
            element: <VacanciesFrom />,
          },
        ],
      },
    ],
  },
  {
    path: "training",
    element: <Training />,
    children: [
      {
        path: "aboutCdo",
        element: <AboutCdo />,
      },
      {
        path: "advancedTraining",
        element: <AdvancedTraining />,
      },
      {
        path: "distanceLearning",
        element: <DistanceLearning />,
      },
      {
        path: "professionalTrainingPrograms",
        element: <ProfessionalTrainingPrograms />,
      },
      {
        path: "addEducationProgramsChildrenAdults",
        element: <AddEducationProgramsChildrenAdults />,
      },
      {
        path: "obsherazvivaushie-programmi",
        element: <ObsherazvivaushieProgrammi />,
      },
      {
        path: "programmi-prof-obuchenia",
        element: <ProgrammiProfObuchenia />,
      },
      {
        path: "programmi-prof-perepodgotovka",
        element: <ProgrammiProfPerepodgotovki />,
      },
      {
        path: "contacts",
        element: <ContactsCdo />,
      },
    ],
  },
  {
    path: "contacts",
    element: <Contacts />,
    children: [
      {
        path: "contactsPage",
        element: <ContactsPage />,
      },
      {
        path: "certificate",
        element: <Certificate />,
      },
      {
        path: "reviews",
        element: <Reviews />,
      },
      {
        path: "makeAnAppointment",
        element: <MakeAnAppointment />,
      },
      {
        path: "makeAnAppointment/:employeeId",
        element: <Appointment />,
      },
      {
        path: "vacancies",
        element: <Vacancies />,
      },
    ],
  },

  {
    path: "home",
    element: <Home />,
    children: [
      {
        path: "nok",
        element: <Nok />,
      },
      {
        path: "site-map",
        element: <SiteMap />,
      },
      {
        path: "anti-corruption",
        element: <AntiCorruption />,
        children: [
          {
            path: "",
            element: <Acts />,
          },
          {
            path: "anti-corruption-expertise",
            element: <AntiCorruptionExpertise />,
          },
          {
            path: "methodological-materials",
            element: <MethodologicalMaterials />,
          },
          {
            path: "document-forms",
            element: <DocumentForms />,
          },
          {
            path: "income-information",
            element: <IncomeInformation />,
          },
          {
            path: "commission",
            element: <Commission />,
          },
          {
            path: "feedback",
            element: <Feedback />,
          },
        ],
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "aboutBranch",
        element: <AboutBranch />,
      },
      {
        path: "stateForPeople",
        element: <StateForPeople />,
      },

      {
        path: "historicalReference",
        element: <HistoricalReference />,
      },
      {
        path: "univercityNews",
        element: <UniversityNews />,
      },

      {
        path: "univercityEvents",
        element: <UniversityEvents />,
      },
    ],
  },
];
export default routes;
